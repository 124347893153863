import React from 'react';
import { motion } from 'framer-motion';

import star1 from './assets/star1.png';
import star2 from './assets/star2.png';
import house1 from './assets/house.png';

const PageHeader = ({page}) => {

    const container = {
        init: {},
        anim: {
            transition : {
                staggerChildren: 5
            }
        }
    }
    const line = {
        init: {},
        anim: {
            transition : {
                staggerChildren: 0
            }
        }
    }
    const title = {
        init: {},
        anim: {
            marginLeft: [0, 20, 0],
            transition: {
                repeat: 'infinity',
                duration: 5,
                repeatDelay: 15,
            }
        }
    }

    return (
        <div className='page-header'>
            <div className='wrapper'>
                <div className='flex'>
                    <div className='star-col'>
                        <img className='star1' src={star1} alt='star1' />
                        <img className='star2' src={star2} alt='star2' />
                    </div>
                    <motion.div variants={container} initial="init" animate='anim' className='title'>
                        <motion.div variants={line}>{page.split("").map((letter, index) => {return(<motion.span key={index} variants={title} className='title-1'>{letter}</motion.span>)})} </motion.div>
                        <motion.div variants={line} style={{marginLeft: '2.2vw'}}>{page.split("").map((letter, index) => {return(<motion.span key={index} variants={title} className='title-2'>{letter}</motion.span>)})} </motion.div>
                        <motion.div variants={line} style={{marginLeft: '4.4vw'}}>{page.split("").map((letter, index) => {return(<motion.span key={index} variants={title} className='title-3'>{letter}</motion.span>)})} </motion.div>
                        <motion.div variants={line} style={{marginLeft: '6.6vw'}}>{page.split("").map((letter, index) => {return(<motion.span key={index} variants={title} className='title-4'>{letter}</motion.span>)})} </motion.div>
                    </motion.div>
                    <motion.img animate={{scale: [1, 1.07, 1]}}  transition={{ repeat: "infinity", duration: 7, repeatDelay: 5}} className='house' src={house1} alt='house' />
                </div>
            </div>
        </div>
    );
};

export default PageHeader;