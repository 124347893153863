import React, { Suspense, useRef, useEffect, useState } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

function KeyFunction(){
    const mesh = useRef();
    const canvasref = useRef();
    const keyPos = useRef();
    const [stickyClass, setStickyClass] = useState(false);
    const gltf = useLoader(GLTFLoader, '/model/key/key.gltf');

    //Play only once after render
    useEffect(() => {
        keyPos.current = canvasref.current.getBoundingClientRect().y;
    }, []);

    //On scroll
    useEffect(() => {
        const stickyKeyRotate = () => {
            if (window !== undefined && mesh.current !== undefined) {
                if(!stickyClass){
                    if(canvasref.current.getBoundingClientRect().y <= 84){
                        setStickyClass(true);
                    }
                }else{
                    let windowScroll = window.scrollY;
                    mesh.current.rotation.z = windowScroll/100;
                    if(window.scrollY < keyPos.current){
                        setStickyClass(false);
                    }
                }
            }
        };
        window.addEventListener('scroll', stickyKeyRotate);
        return () => {
            window.removeEventListener('scroll', stickyKeyRotate);
        };
    });

    return (
        <div className='key'>
            <div ref={canvasref} className= {'key-frame' + (stickyClass ? ' sticky-key' :'')} >
                <Canvas>
                    <ambientLight />
                    <pointLight position={[10, 10, 10]} intensity={2}/>
                    <primitive ref={mesh} object={gltf.scene} position={[0, 0, 0]} rotation={[90*(Math.PI/180), 0, 0]}/>
                </Canvas>
            </div>
        </div>
    );
}


function Key(){
    return (
            
            <Suspense fallback={null}>
                <KeyFunction />
            </Suspense>
    );
}

export default Key;