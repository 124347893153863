import React from 'react';
import partenaire1 from './assets/partenaire1.png';
import partenaire2 from './assets/partenaire2.png';
import partenaire3 from './assets/partenaire3.png';
import partenaire4 from './assets/partenaire4.png';
import partenaire5 from './assets/partenaire5.png';
import partenaire6 from './assets/partenaire6.png';
import partenaire7 from './assets/partenaire7.png';
import partenaire8 from './assets/partenaire8.png';
import partenaire9 from './assets/partenaire9.png';

const ScrollingPartenaire = () => {
    const images =[
        partenaire1,
        partenaire2,
        partenaire3,
        partenaire4,
        partenaire5,
        partenaire6,
        partenaire7,
        partenaire8,
        partenaire9
    ];
    return (
        <div className='scrolling-partenaire'>
            <h3>Nos partenaires nous fournissent des offres exclusives</h3>
            <div className='container'>
                <div className='partenaire-wrapper toleft'>
                    { images.map((image, index) => {
                        return (
                            <div key={index} className='image-wrapper'>
                                <img className='image' src={image} alt={'partenaire-' + index } />
                            </div>
                        )
                    })}
                </div>
                <div className='partenaire-wrapper toleft'>
                    { images.map((image, index) => {
                        return (
                            <div key={index} className='image-wrapper'>
                                <img className='image' src={image} alt={'partenaire-' + index } />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default ScrollingPartenaire;