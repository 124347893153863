import React, { Suspense, useRef, useState } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Spinner, Drag, MouseScroll } from '../styles/assets/SvgLibrary';

const ModernHouse = () =>{
    const mesh = useRef();
    const gltf = useLoader(GLTFLoader, '/model/modern/scene.gltf');

    return (
        <Canvas>
            <OrbitControls enablePan={false} maxPolarAngle={1.7} minPolarAngle={1} maxAzimuthAngle={-2.3} minAzimuthAngle={2.4} minDistance={450} maxDistance={650}/>
            <PerspectiveCamera makeDefault position={[0, 0, -550]} fov={90}/>
            <ambientLight />
            <pointLight position={[10, 10, 10]} intensity={2}/>
            <primitive ref={mesh} object={gltf.scene} position={[-350, -100, 500]}/>
        </Canvas>
    );
}
const AtypicalHouse = () =>{
    const mesh = useRef();
    const gltf = useLoader(GLTFLoader, '/model/atypical/scene.gltf');

    return (
        <Canvas>
            <OrbitControls enablePan={false}/>
            <PerspectiveCamera makeDefault position={[ 0, 1.2, -7]} fov={90}/>
            <ambientLight />
            <pointLight position={[10, 10, -10]} intensity={1}/>
            <primitive ref={mesh} object={gltf.scene} position={[0, -3, 0]} rotation={[0, 3.14, 0]}/>
        </Canvas>
    );
}

const House = () => {
    const [activeTab, setActiveTab] = useState(1);
    return (
        <div className='house-frame'>
            <h3>Visualisation 3D de nos types de biens :</h3>
            <div className='house-tabs'>
                <div className={'house-tab' + (activeTab === 1 ? ' active' : '')} onClick={() =>setActiveTab(1)}>Moderne</div>
                <div className={'house-tab' + (activeTab === 2 ? ' active' : '')} onClick={() =>setActiveTab(2)}>Atypique</div>
            </div>
            <div className='house-show'>
                <div className='show-icons'>
                    <Drag width='60px' color='#2A8BBB'/>
                    <MouseScroll width='60px' color='#2A8BBB'/>
                </div>
                <Suspense fallback={<Spinner width='90' color='#2A8BBB'/>}>
                    {activeTab === 1 ? <ModernHouse /> : <AtypicalHouse />}
                </Suspense>
            </div>
        </div>
    );
}

export default House;