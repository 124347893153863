import React from 'react';
import Nav from '../components/Nav';
import PageHeader from '../components/PageHeader';
import ScrollingText from '../components/ScrollingText';
import ServicesVente from '../components/Vente';
import Faq from '../components/Faq';
import Footer from '../components/Footer';

const Vendre = () => {
    const lines =[
        "12 sites - +500 ventes par mois - + de 200 client - 9 continents - 5 langues dolor sit amet, consectetuer adipiscing elit, sed diam nonummy -",
        "700 batiments 12 000 batises - 4 structures - reconnu par l’état - agréer des organismes immobilier - Lorem ipsum Lorem ipsum dolor sit -"
    ];
    return (
        <>
            <Nav />
            <PageHeader page='vendre' />
            <ScrollingText lines={lines}/>
            <ServicesVente />
            <Faq />
            <Footer />
        </>
    );
};

export default Vendre;