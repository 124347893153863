import React, { useRef, useEffect } from "react";
import { motion, useMotionValue } from "framer-motion";

import variables from '../../styles/_variables.scss';
import { Immovable, Notary, Broker} from "./assets/Svgs";
import picture1 from "./assets/picture1.jpg";
import picture2 from "./assets/picture2.jpg";
import picture3 from "./assets/picture3.jpg";
import picture4 from "./assets/picture4.jpg";

const Connect = () => {
  const pathRefOne = useRef(null);
  const progressLengthOne = useMotionValue(0);
  const progressXOne = useMotionValue(0);
  const progressYOne = useMotionValue(0);

  const pathRefTwo = useRef(null);
  const progressLengthTwo = useMotionValue(0);
  const progressXTwo = useMotionValue(0);
  const progressYTwo = useMotionValue(0);


  const pathRefThree = useRef(null);
  const progressLengthThree = useMotionValue(0);
  const progressXThree = useMotionValue(0);
  const progressYThree = useMotionValue(0);

  const pathRefFour = useRef(null);
  const progressLengthFour = useMotionValue(0);
  const progressXFour = useMotionValue(0);
  const progressYFour = useMotionValue(0);

  const transition = {
    duration: 4,
    repeat: Infinity,
    repeatType: "reverse",
    ease: "easeInOut",
  };

  useEffect(() => {
    const pathOne = pathRefOne.current;
    const totalPathLengthOne = pathOne.getTotalLength();
    const initialProgressOne = progressLengthOne.get();
    const initialCoordsOne = pathOne.getPointAtLength(
      initialProgressOne * totalPathLengthOne
    );

    progressXOne.set(initialCoordsOne.x);
    progressYOne.set(initialCoordsOne.y);

    const unsubscribeOne = progressLengthOne.on("change", (latestPercent) => {
      const latestPathProgressOne = pathOne.getPointAtLength(
        latestPercent * totalPathLengthOne
      );
      progressXOne.set(latestPathProgressOne.x);
      progressYOne.set(latestPathProgressOne.y);
    });

    return unsubscribeOne;
  }, [progressLengthOne, progressXOne, progressYOne]);

  useEffect(() => {
    const pathTwo = pathRefTwo.current;
    const totalPathLengthTwo = pathTwo.getTotalLength();
    const initialProgressTwo = progressLengthTwo.get();
    const initialCoordsTwo = pathTwo.getPointAtLength(
      initialProgressTwo * totalPathLengthTwo
    );
    progressXTwo.set(initialCoordsTwo.x);
    progressYTwo.set(initialCoordsTwo.y);

    const unsubscribeTwo = progressLengthTwo.on("change", (latestPercent) => {
      const latestPathProgressTwo = pathTwo.getPointAtLength(
        latestPercent * totalPathLengthTwo
      );
      
      progressXTwo.set(latestPathProgressTwo.x);
      progressYTwo.set(latestPathProgressTwo.y);
    });

    return unsubscribeTwo;
  }, [progressLengthTwo, progressXTwo, progressYTwo]);

  useEffect(() => {
    const pathThree = pathRefThree.current;
    const totalPathLengthThree = pathThree.getTotalLength();
    const initialProgressThree = progressLengthThree.get();
    const initialCoordsThree = pathThree.getPointAtLength(
      initialProgressThree * totalPathLengthThree
    );
    progressXThree.set(initialCoordsThree.x);
    progressYThree.set(initialCoordsThree.y);
    const unsubscribeThree = progressLengthThree.on("change", (latestPercent) => {
      const latestPathProgressThree = pathThree.getPointAtLength(
        latestPercent * totalPathLengthThree
      );
      
      progressXThree.set(latestPathProgressThree.x);
      progressYThree.set(latestPathProgressThree.y);
    });
    return unsubscribeThree;
  }, [progressLengthThree, progressXThree, progressYThree]);

  useEffect(() => {
    const pathFour = pathRefFour.current;
    const totalPathLengthFour = pathFour.getTotalLength();
    const initialProgressFour = progressLengthFour.get();
    const initialCoordsFour = pathFour.getPointAtLength(
      initialProgressFour * totalPathLengthFour
    );
    progressXFour.set(initialCoordsFour.x);
    progressYFour.set(initialCoordsFour.y);
    const unsubscribeFour = progressLengthFour.on("change", (latestPercent) => {
      const latestPathProgressTFour = pathFour.getPointAtLength(
        latestPercent * totalPathLengthFour
      );
      
      progressXFour.set(latestPathProgressTFour.x);
      progressYFour.set(latestPathProgressTFour.y);
    });

    return unsubscribeFour;
  }, [progressLengthFour, progressXFour, progressYFour]);

  return (
    <>
      <div className="connect">
        <div className="wrapper">
          <div className="connect-title">
            <h3>L'interconnectivité</h3>
            <span>Nous nous occupons de transfèrer les differents documents entre les différents organismes</span>
          </div>
          <img className="circle c1" src={picture1} alt="picture1" />
          <div className="circle c2"><Immovable width={"2.35vw"} color={variables.color2}/></div>
          <img className="circle c3" src={picture3} alt="picture3" />
          <div className="circle c4"><Notary width={"2.35vw"} color={variables.color2}/></div>
          <img className="circle c5" src={picture2} alt="picture2" />
          <img className="circle c6" src={picture4} alt="picture4" />
          <div className="circle c7"><Broker width={"2.35vw"} color={variables.color2}/></div>
          <svg
            viewBox="0 0 1440 498"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              stroke="transparent"
              d="M 931.50038 217.81674 A 397.5 118 0 0 1 719.5 236 A 397.5 118 0 0 1 485.86353 213.46577"
              ref={pathRefOne}
              pathLength={progressLengthOne}
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={transition}
            ></motion.path>
            <motion.circle cx={progressXOne} cy={progressYOne} initial={{ r: 5 }} animate={{ r: 10 }}  transition={transition} fill="transparent" stroke="url(#color0)" />
            <motion.circle cx={progressXOne} cy={progressYOne} r="5" fill="white" />
            <path
              d="M1116.5 118C1116.5 134.048 1105.54 149.438 1085.48 163.522C1065.42 177.595 1036.37 190.29 1000.43 200.959C928.557 222.296 829.232 235.5 719.5 235.5C609.768 235.5 510.443 222.296 438.567 200.959C402.626 190.29 373.577 177.595 353.525 163.522C333.458 149.438 322.5 134.048 322.5 118C322.5 101.952 333.458 86.5619 353.525 72.4783C373.577 58.4045 402.626 45.71 438.567 35.0407C510.443 13.7041 609.768 0.5 719.5 0.5C829.232 0.5 928.557 13.7041 1000.43 35.0407C1036.37 45.71 1065.42 58.4045 1085.48 72.4783C1105.54 86.5619 1116.5 101.952 1116.5 118Z"
              stroke="url(#color0)"
              strokeOpacity="0.1"
            ></path>
            <ellipse
              cx="719.5"
              cy="118"
              rx="397.5"
              ry="118"
              fill="url(#color1)"
            ></ellipse>
            <ellipse
              cx="719.5"
              cy="191"
              rx="576.5"
              ry="171"
              fill="url(#color2)"
            ></ellipse>
            <motion.path
              ref={pathRefTwo}
              pathLength={progressLengthTwo}
              initial={{ pathLength: 1 }}
              animate={{ pathLength: 0 }}
              transition={transition}
              stroke="transparent"
              d="M 842.118 357.62306 C 802.60437 360.16245 761.57935 361.5 719.5 361.5 C 560.338 361.5 416.263 342.364 311.995 311.436 C 293.28929 305.88747 275.86837 299.96049 259.87373 293.69806 "
            ></motion.path>
             <motion.circle cx={progressXTwo} cy={progressYTwo} initial={{ r: 5 }} animate={{ r: 10 }}  transition={transition} fill="transparent" stroke="url(#color0)" />
             <motion.circle cx={progressXTwo} cy={progressYTwo} r="5" fill="white" />
            <motion.path
              ref={pathRefThree}
              pathLength={progressLengthThree}
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={transition}
              stroke="transparent"
              d="M 1207.7199 281.42751 C 1184.6471 292.314 1157.535 302.37916 1127 311.436 C 1050.3043 334.18724 952.0617 350.5574 842.118 357.62306 "
            ></motion.path>
            <motion.circle cx={progressXThree} cy={progressYThree} initial={{ r: 5 }} animate={{ r: 10 }}  transition={transition} fill="transparent" stroke="url(#color0)" />
            <motion.circle cx={progressXThree} cy={progressYThree} r="5" fill="white" />
            <path
              d="M1295.5 191C1295.5 214.365 1279.53 236.727 1250.41 257.152C1221.3 277.566 1179.14 295.971 1127 311.436C1022.74 342.364 878.662 361.5 719.5 361.5C560.338 361.5 416.263 342.364 311.995 311.436C259.858 295.971 217.702 277.566 188.591 257.152C159.467 236.727 143.5 214.365 143.5 191C143.5 167.635 159.467 145.273 188.591 124.848C217.702 104.434 259.858 86.0289 311.995 70.5641C416.263 39.6363 560.338 20.5 719.5 20.5C878.662 20.5 1022.74 39.6363 1127 70.5641C1179.14 86.0289 1221.3 104.434 1250.41 124.848C1279.53 145.273 1295.5 167.635 1295.5 191Z"
              stroke="url(#color3)"
              strokeOpacity="0.1"
            ></path>
            <motion.path
              ref={pathRefFour}
              pathLength={progressLengthFour}
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={transition}
              stroke="transparent"
              d="M 977.51391 469.46913 C 897.55864 478.5331 810.74174 483.5 720 483.5 C 618.5839 483.5 522.07088 477.29581 434.56067 466.10175 "
            ></motion.path>
            <motion.circle cx={progressXFour} cy={progressYFour} initial={{ r: 5 }} animate={{ r: 10 }}  transition={transition} fill="transparent" stroke="url(#color0)" />
            <motion.circle cx={progressXFour} cy={progressYFour} r="5" fill="white" />
            <path
              d="M1439.5 271C1439.5 300.164 1419.52 328.05 1383.13 353.499C1346.76 378.939 1294.1 401.869 1228.98 421.134C1098.74 459.663 918.788 483.5 720 483.5C521.212 483.5 341.262 459.663 211.025 421.134C145.903 401.869 93.2397 378.939 56.8677 353.499C20.4816 328.05 0.5 300.164 0.5 271C0.5 241.836 20.4816 213.95 56.8677 188.501C93.2397 163.061 145.903 140.131 211.025 120.866C341.262 82.3374 521.212 58.5 720 58.5C918.788 58.5 1098.74 82.3374 1228.98 120.866C1294.1 140.131 1346.76 163.061 1383.13 188.501C1419.52 213.95 1439.5 241.836 1439.5 271Z"
              stroke="url(#color4)"
              strokeOpacity="0.1"
            ></path>
            <ellipse
              cx="720"
              cy="271"
              rx="720"
              ry="213"
              fill="url(#color5)"
            ></ellipse>
            <defs>
              <linearGradient
                id="color0"
                x1="719.5"
                y1="0"
                x2="719.5"
                y2="236"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0.149299"
                  stopColor="#FAFAF3"
                  stopOpacity="0"
                ></stop>
                <stop offset="1" stopColor="#FAFAF3"></stop>
              </linearGradient>
              <linearGradient
                id="color1"
                x1="719.5"
                y1="78.5"
                x2="719.5"
                y2="278.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7A51DE" stopOpacity="0"></stop>
                <stop offset="1" stopColor="#FAFAF3" stopOpacity="0.08"></stop>
              </linearGradient>
              <linearGradient
                id="color2"
                x1="719.5"
                y1="133.758"
                x2="719.5"
                y2="423.589"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7A51DE" stopOpacity="0"></stop>
                <stop offset="1" stopColor="#FAFAF3" stopOpacity="0.08"></stop>
              </linearGradient>
              <linearGradient
                id="color3"
                x1="719.5"
                y1="20"
                x2="719.5"
                y2="362"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0.149299"
                  stopColor="#FAFAF3"
                  stopOpacity="0"
                ></stop>
                <stop offset="1" stopColor="#FAFAF3"></stop>
              </linearGradient>
              <linearGradient
                id="color4"
                x1="720"
                y1="58"
                x2="720"
                y2="484"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0.149299"
                  stopColor="#FAFAF3"
                  stopOpacity="0"
                ></stop>
                <stop offset="1" stopColor="#FAFAF3"></stop>
              </linearGradient>
              <linearGradient
                id="color5"
                x1="720"
                y1="199.699"
                x2="720"
                y2="560.716"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7A51DE" stopOpacity="0"></stop>
                <stop offset="1" stopColor="#FAFAF3" stopOpacity="0.08"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className="wrapper">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 496.76 45.26"
        >
          <path
            fill="#2B8BBB"
            d="M460.8,0C416.6,14.6,338,24.3,248.4,24.3S80.2,14.6,36,0H460.8z"
          />
        </svg>
      </div>
    </>
  );
};

export default Connect;
