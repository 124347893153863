import React, {useState} from 'react';
import { motion } from 'framer-motion';
import { Arrow } from '../../styles/assets/SvgLibrary';

import ask from './assets/ask.png';

const PourquoiAcheter = () => {
    const [isTopLeftHovered, setTopLeftHovered] = useState(false);
    const [isTopRightHovered, setTopRightHovered] = useState(false);
    const [isBottomHovered, setBottomHovered] = useState(false);

    return (
        <div className='wrapper pourquoiacheter'>
            <div className='left'>
                <span>Pourquoi acheter ?</span>
                <h3>LD Immobilier vous conseilleras en fonction de ce que vous voulez faire de votre achat</h3>
                <img src={ask} alt="ask-acheter" />
            </div>
            <div className='right'>
                <div className='top'>
                    <div className='top-left'>
                        <motion.div className='top-left-content' animate={{backgroundColor: isTopLeftHovered ? "#9D69A3" : "#FFFFFF",color: isTopLeftHovered ? "#FFFFFF" : "#000000"}} onMouseEnter={() => setTopLeftHovered(true)} onMouseLeave={() => setTopLeftHovered(false)}>
                            <motion.h4 animate={{color: isTopLeftHovered ? "#FFFFFF" : "#9D69A3"}}>Vivre</motion.h4>
                            <p>Aenean sed turpis luctus, commodo ante et, convallis leo</p>
                            <motion.div animate={{border: isTopLeftHovered ? "1px solid #FFFFFF" : "1px solid #9D69A3"}} className='square'><Arrow width="22" color={isTopLeftHovered ? "#FFFFFF" :"#9D69A3"} /></motion.div>
                            <motion.svg className='svgHouse' animate={{scale: isTopLeftHovered ? 1.3 : 1, bottom : isTopLeftHovered ? "40px" : "10px" , right: isTopLeftHovered ? "40px" :  "10px"}} xmlns="http://www.w3.org/2000/svg" width={"180px"} height={"180px"} viewBox="0 0 180.74 175.11"><path d="M88,.8,1.64,65.58A4,4,0,0,0,4,72.81h7.6a4,4,0,0,1,4,4V171.1a4,4,0,0,0,4,4H97.75a4,4,0,0,0,4-4V99.4a4,4,0,0,1,4-4h35.44a4,4,0,0,1,4,4v71.71a4,4,0,0,0,4,4H161.1a4,4,0,0,0,4-4V76.82a4,4,0,0,1,4-4h7.59a4,4,0,0,0,2.41-7.23L92.83.8A4,4,0,0,0,88,.8ZM45.44,136.4H34a4,4,0,0,1-4-4V120.94a4,4,0,0,1,4-4H45.44a4,4,0,0,1,4,4v11.44a4,4,0,0,1-4,4Zm0-21.55H34a4,4,0,0,1-4-4V99.4a4,4,0,0,1,4-4H45.44a4,4,0,0,1,4,4v11.43a4,4,0,0,1-4,4ZM67.55,136.4H56.11a4,4,0,0,1-4-4V120.94a4,4,0,0,1,4-4H67.55a4,4,0,0,1,4,4v11.44a4,4,0,0,1-4,4Zm0-21.55H56.11a4,4,0,0,1-4-4V99.4a4,4,0,0,1,4-4H67.55a4,4,0,0,1,4,4v11.43a4,4,0,0,1-4,4Z" style={{fill: isTopLeftHovered ? "#cfb4d1" : "#9e69a3"}}/></motion.svg>
                        </motion.div>
                    </div>
                    <div className='top-right'>
                        <motion.div className='top-right-content' animate={{backgroundColor: isTopRightHovered ? "#F48F64" : "#FFFFFF",color: isTopRightHovered ? "#FFFFFF" : "#000000"}} onMouseEnter={() => setTopRightHovered(true)} onMouseLeave={() => setTopRightHovered(false)}>
                            <motion.h4 animate={{color: isTopRightHovered ? "#FFFFFF" : "#F48F64"}}>Louer le bien</motion.h4>
                            <p>Aenean sed turpis luctus, commodo ante et, convallis leo</p>
                            <motion.div animate={{border: isTopRightHovered ? "1px solid #FFFFFF" : "1px solid #F48F64"}} className='square'><Arrow width="22" color={isTopRightHovered ? "#FFFFFF" :"#F48F64"} /></motion.div>
                        </motion.div>
                    </div>
                </div>
                <div className='bottom'>
                    <motion.div className='bottom-content' animate={{backgroundColor: isBottomHovered ? "#2A8BBB" : "#FFFFFF",color: isBottomHovered ? "#FFFFFF" : "#000000"}} onMouseEnter={() => setBottomHovered(true)} onMouseLeave={() => setBottomHovered(false)}>
                        <div className='bottom-text'>
                            <motion.h4 animate={{color: isBottomHovered ? "#FFFFFF" : "#2A8BBB"}}>Revendre</motion.h4>
                            <p>Aenean sed turpis luctus, commodo ante et, convallis leo</p>
                            <motion.div animate={{border: isBottomHovered ? "1px solid #FFFFFF" : "1px solid #2A8BBB"}} className='square'><Arrow width="22" color={isBottomHovered ? "#FFFFFF" :"#2A8BBB"} /></motion.div>
                        </div>
                        <div className='slider-icon'>
                            <div className='icon-top'>
                                <motion.div className='bar' animate={{backgroundColor: isBottomHovered ? "#79cdf4" : "#2A8BBB"}}></motion.div>
                                <div className='dot'></div>
                            </div>
                            <div className='icon-bottom'>
                                <motion.div className='bar' animate={{backgroundColor: isBottomHovered ? "#79cdf4" : "#2A8BBB"}}></motion.div>
                                <motion.div className='dot' animate={{left: isBottomHovered ? "auto" : "7px", right: isBottomHovered ? "7px" : "auto"}}></motion.div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default PourquoiAcheter;