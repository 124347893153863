import React, {useRef} from 'react';
import localite from './assets/localite.mp4';
import biens from './assets/type.mp4';
import commodite from './assets/commodite.mp4';

const BesoinLouer = () => {
    const card1 = useRef();
    const card2 = useRef();
    const card3 = useRef();

    const growUp = (target) => {
        if(target === card1.current){
            card1.current.style.width = '600px';
            card2.current.style.width = '350px';
            card3.current.style.width = '350px';
            card1.current.style.height = '400px';
            card1.current.getElementsByClassName('card-text')[0].style.opacity = 1;
        }else if(target === card2.current){
            card2.current.style.width = '600px';
            card1.current.style.width = '350px';
            card3.current.style.width = '350px';
            card2.current.style.height = '390px';
            card2.current.getElementsByClassName('card-text')[0].style.opacity = 1;
        }else if(target === card3.current){
            card3.current.style.width = '600px';
            card1.current.style.width = '350px';
            card2.current.style.width = '350px';
            card3.current.style.height = '380px';
            card3.current.getElementsByClassName('card-text')[0].style.opacity = 1;
        }
    }
    const growDown = () => {
        card1.current.style.width = null;
        card2.current.style.width = null;
        card3.current.style.width = null;
        card1.current.style.height = null;
        card2.current.style.height = null;
        card3.current.style.height = null;
        card1.current.getElementsByClassName('card-text')[0].style.opacity = 0;
        card2.current.getElementsByClassName('card-text')[0].style.opacity = 0;
        card3.current.getElementsByClassName('card-text')[0].style.opacity = 0;
    }

    return (
        <div className='besoin-louer wrapper'>
            <h3>Définissez vos besoins !</h3>
            <div className='flex-cards'>
                <div className='card' ref={card1} onMouseOver={(e) => growUp(e.target)} onMouseLeave={(e) => growDown()}>
                    <h4>Localités</h4>
                    <div className='card-text'> Etiam nec ligula et purus posuere venenatis vitae sed lacus. Fusce tincidunt aliquet ante eu hendrerit. Nullam nisi quam, pretium et porta in, ullamcorper in lacus. Fusce quis lorem ut orci iaculis venenatis nec ac velit. Vestibulum placerat velit ac orci elementum bibendum.</div>
                    <div className='video-container localite-mask'>
                        <video src={localite} type="video/mp4" muted autoPlay loop  />
                    </div>
                </div>
                <div className='card' ref={card2} onMouseOver={(e) => growUp(e.target)} onMouseLeave={(e) => growDown()}>
                    <h4>Type de bien</h4>
                    <div className='card-text'> Etiam nec ligula et purus posuere venenatis vitae sed lacus. Fusce tincidunt aliquet ante eu hendrerit. Nullam nisi quam, pretium et porta in, ullamcorper in lacus. Fusce quis lorem ut orci iaculis venenatis nec ac velit. Vestibulum placerat velit ac orci elementum bibendum.</div>
                    <div className='video-container type-mask'>
                        <video src={biens} type="video/mp4" muted autoPlay loop  />
                    </div>
                </div>
                <div className='card' ref={card3} onMouseOver={(e) => growUp(e.target)} onMouseLeave={(e) => growDown()}>
                    <h4>Commodités</h4>
                    <div className='card-text'> Etiam nec ligula et purus posuere venenatis vitae sed lacus. Fusce tincidunt aliquet ante eu hendrerit. Nullam nisi quam, pretium et porta in, ullamcorper in lacus. Fusce quis lorem ut orci iaculis venenatis nec ac velit. Vestibulum placerat velit ac orci elementum bibendum.</div>
                    <div className='video-container comodite-mask'>
                        <video src={commodite} type="video/mp4" muted autoPlay loop  />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default BesoinLouer;