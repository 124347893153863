import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Vendre from "../pages/Vendre";
import Louer from "../pages/Louer";
import Acheter from "../pages/Acheter";
import ScrollToTop from "./ScrollToTop";
import Contact from "../pages/Contact";

const Routing = () => {
    return (
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/vendre" exact element={<Vendre />} />
          <Route path="/louer" exact element={<Louer />} />
          <Route path="/acheter" exact element={<Acheter />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    );
  };
  
  export default Routing;
  