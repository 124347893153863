import React from 'react';

//images
import fullstar from './assets/fullstar.png'
import emptystar from './assets/emptystar.png'

const CommentCard = ({comment}) => {

    const showStars = (rate) => {
        let stars = [];
        for(let i= 0; i < 5; i++){
            if(i < rate){
                stars.push(<img  key={i} className='star' src={fullstar} alt='full-star' />) 
            }else{
                stars.push(<img key={i} className='star' src={emptystar} alt='empty-star' />)
            }
        }
        return stars;
    }

    return (
        <div className='comment-card'>
            <div className='comment-header'>
                <img className='avatar' src={comment.img} alt='avatar'/>
                <div className='name-container col'>
                    <span className='name'>{comment.name}</span>
                    <div>
                        {showStars(comment.rate)}
                    </div>
                    <span className='desc'>{comment.desc}</span>
                </div>
            </div>
            <div className='comment-content'>
                {comment.text}
            </div>
            <span className='date'>{comment.date}</span>
        </div>
    );
};

export default CommentCard;