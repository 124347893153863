import React from 'react';
import MenuWrapper from '../MenuWrapper';
import house1 from './assets/house1.png';
import house2 from './assets/house2.png';
import house3 from './assets/house3.png';

const index = () => {
    const menuObject = [
        {
            title : "Comment vendre ?",
            text : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum dignissim at sem ut viverra. Aliquam id libero egestas risus aliquet scelerisque. Sed maximus tincidunt ante, ac sollicitudin diam feugiat non. Donec et augue ac lacus faucibus cursus. Phasellus eu molestie neque, at congue nunc. Maecenas luctus suscipit ante, vel pulvinar magna rhoncus vitae. Curabitur in justo ut ante tincidunt porta vel et sapien. Curabitur egestas est ut odio convallis, lobortis molestie nibh tempus. Ut mattis ex dui, quis pellentesque odio posuere non. Nunc mattis ligula semper leo pharetra semper. Donec posuere eu magna quis dictum. Sed ultrices sapien magna, ut eleifend orci dictum id. Integer lobortis nec ante vel scelerisque. Phasellus sed bibendum sapien. Donec id rutrum ipsum, a dictum metus."
        },
        {
            title : "A qui s'adresser ?",
            text : "Suspendisse id gravida diam. Aliquam faucibus, lectus hendrerit facilisis imperdiet, nisi ante egestas nibh, sit amet fringilla ipsum felis eget dolor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas mollis mi vel lorem posuere vehicula sit amet eget lacus. Suspendisse tincidunt faucibus neque sed dignissim. Sed placerat egestas nisl sit amet vulputate. Mauris est eros, ornare non finibus vel, dapibus nec orci. Quisque vestibulum egestas nulla, vitae consectetur neque imperdiet ultricies. Cras ac ex interdum, venenatis ex posuere, faucibus turpis. Mauris ac posuere dui. Nam a blandit enim. Morbi suscipit vel felis vulputate accumsan. Suspendisse quis purus pulvinar, tempor ante accumsan, maximus diam."
        },
        {
            title : "Comment toucher une clientelle ?",
            text : "Maecenas sodales, metus porta ornare maximus, nisi felis scelerisque elit, quis elementum libero augue at arcu. Quisque consectetur odio sed vehicula consequat. Mauris vestibulum porta vestibulum. Donec ultricies tellus lectus, in tincidunt massa varius quis. Donec vel ex turpis. Praesent nec nisi eu mauris tincidunt porttitor. Aliquam condimentum tortor feugiat metus volutpat auctor."
        },
        {
            title : "Comment vendre ?",
            text : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum dignissim at sem ut viverra. Aliquam id libero egestas risus aliquet scelerisque. Sed maximus tincidunt ante, ac sollicitudin diam feugiat non. Donec et augue ac lacus faucibus cursus. Phasellus eu molestie neque, at congue nunc. Maecenas luctus suscipit ante, vel pulvinar magna rhoncus vitae. Curabitur in justo ut ante tincidunt porta vel et sapien. Curabitur egestas est ut odio convallis, lobortis molestie nibh tempus. Ut mattis ex dui, quis pellentesque odio posuere non. Nunc mattis ligula semper leo pharetra semper. Donec posuere eu magna quis dictum. Sed ultrices sapien magna, ut eleifend orci dictum id. Integer lobortis nec ante vel scelerisque. Phasellus sed bibendum sapien. Donec id rutrum ipsum, a dictum metus."
        },
        {
            title : "A qui s'adresser ?",
            text : "Suspendisse id gravida diam. Aliquam faucibus, lectus hendrerit facilisis imperdiet, nisi ante egestas nibh, sit amet fringilla ipsum felis eget dolor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas mollis mi vel lorem posuere vehicula sit amet eget lacus. Suspendisse tincidunt faucibus neque sed dignissim. Sed placerat egestas nisl sit amet vulputate. Mauris est eros, ornare non finibus vel, dapibus nec orci. Quisque vestibulum egestas nulla, vitae consectetur neque imperdiet ultricies. Cras ac ex interdum, venenatis ex posuere, faucibus turpis. Mauris ac posuere dui. Nam a blandit enim. Morbi suscipit vel felis vulputate accumsan. Suspendisse quis purus pulvinar, tempor ante accumsan, maximus diam."
        },
        {
            title : "Comment toucher une clientelle ?",
            text : "Maecenas sodales, metus porta ornare maximus, nisi felis scelerisque elit, quis elementum libero augue at arcu. Quisque consectetur odio sed vehicula consequat. Mauris vestibulum porta vestibulum. Donec ultricies tellus lectus, in tincidunt massa varius quis. Donec vel ex turpis. Praesent nec nisi eu mauris tincidunt porttitor. Aliquam condimentum tortor feugiat metus volutpat auctor."
        },
        {
            title : "Comment vendre ?",
            text : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum dignissim at sem ut viverra. Aliquam id libero egestas risus aliquet scelerisque. Sed maximus tincidunt ante, ac sollicitudin diam feugiat non. Donec et augue ac lacus faucibus cursus. Phasellus eu molestie neque, at congue nunc. Maecenas luctus suscipit ante, vel pulvinar magna rhoncus vitae. Curabitur in justo ut ante tincidunt porta vel et sapien. Curabitur egestas est ut odio convallis, lobortis molestie nibh tempus. Ut mattis ex dui, quis pellentesque odio posuere non. Nunc mattis ligula semper leo pharetra semper. Donec posuere eu magna quis dictum. Sed ultrices sapien magna, ut eleifend orci dictum id. Integer lobortis nec ante vel scelerisque. Phasellus sed bibendum sapien. Donec id rutrum ipsum, a dictum metus."
        },
        {
            title : "A qui s'adresser ?",
            text : "Suspendisse id gravida diam. Aliquam faucibus, lectus hendrerit facilisis imperdiet, nisi ante egestas nibh, sit amet fringilla ipsum felis eget dolor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas mollis mi vel lorem posuere vehicula sit amet eget lacus. Suspendisse tincidunt faucibus neque sed dignissim. Sed placerat egestas nisl sit amet vulputate. Mauris est eros, ornare non finibus vel, dapibus nec orci. Quisque vestibulum egestas nulla, vitae consectetur neque imperdiet ultricies. Cras ac ex interdum, venenatis ex posuere, faucibus turpis. Mauris ac posuere dui. Nam a blandit enim. Morbi suscipit vel felis vulputate accumsan. Suspendisse quis purus pulvinar, tempor ante accumsan, maximus diam."
        },
        {
            title : "Comment toucher une clientelle ?",
            text : "Maecenas sodales, metus porta ornare maximus, nisi felis scelerisque elit, quis elementum libero augue at arcu. Quisque consectetur odio sed vehicula consequat. Mauris vestibulum porta vestibulum. Donec ultricies tellus lectus, in tincidunt massa varius quis. Donec vel ex turpis. Praesent nec nisi eu mauris tincidunt porttitor. Aliquam condimentum tortor feugiat metus volutpat auctor."
        },
    ];
    return (
        <div className='menu-div'>
        <div className='wrapper menu-grid'>
            <div className='menu-left'>
            <h3>Qu'avez vous besoins de savoir a propros de la vente ?</h3>
                <div className='image-container'>
                    <img className='house1' src={house1} alt='house1' />
                    <img className='house2' src={house3} alt='house2' />
                    <img className='house3' src={house2} alt='house3' />
                </div>
            </div>
            <div className='menu-right'>
                <div className="border-left"></div>
                <MenuWrapper menuObject= {menuObject} />
            </div>
        </div>
    </div>
    );
};

export default index;