import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import Avantages from '../components/Home/Avantages';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import Presentation from '../components/Home/Presentation';
import Resultats from '../components/Home/Resultats';
import Services from '../components/Home/Services';

const Home = () => {
    const [showNav, setShowNav] = useState(false);
    const presentationContainer = useRef(null);

    const checkScrollY = () => {
        if(window.pageYOffset > (presentationContainer.current.offsetHeight-86)){
            setShowNav(true);
        }
        else{
            setShowNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", checkScrollY);
        return () => {
            window.removeEventListener("scroll", checkScrollY);
          };
    });

    return (
        <div className='home'>
            <AnimatePresence>{showNav && <motion.div style={{position: 'fixed', zIndex: 10, top:0, left: 0, width:'100%'}} initial={{y:-100, opacity: 0}} transition={{ ease: 'linear'}} animate={{y:0, opacity:1}} exit={{y:-100,opacity: 0}}><Nav /></motion.div>}</AnimatePresence>
            <div ref={presentationContainer}>
                <Presentation />
            </div>
            <Services />
            <Resultats />
            <Avantages />
            <Footer />
        </div>
    );
};

export default Home;