import React from 'react';

// images
import houses from './assets/houses.png';
import house from './assets/house.png';
import de1 from './assets/de1.png';
import de2 from './assets/de2.png';
import euro from './assets/euro.png';
import loupe from './assets/loupe.png';
import pieces from './assets/pieces.png';

const Services = () => {
    return (
        <div className='services'>
            <div className='services-grid wrapper'>
                <div className='left'>
                    <div className='cibler'>
                        <h3>Cibler vos besoins</h3>
                        <div className='flex'>
                            <img className='houses' src={houses} alt='houses' />
                            <div className='flex col'>
                                <div className='text'>
                                    Lorem <span>ipsum</span> dolor sit amet, consectetur adipiscing elit. Quisque <span>efficitur</span> ex id ultricies semper. 
                                    In sed turpis ac quam <span>sodales</span> ullamcorper. Vivamus a ligula ac enim sodales congue. <span>Aenean</span> quis augue vel enim semper <span>vestibulum</span> at at nibh. 
                                    <br/><br/>
                                    Maecenas <span>feugiat</span> sem massa, id <span>consequat</span> orci dapibus eu. Morbi eu lacus nulla. Aenean porttitor rutrum tempor. 
                                    Nam maximus <span>massa</span> ac dui hendrerit, ac posuere lorem ultrices. Lorem ipsum dolor sit amet, consectetuer <span>adipiscing</span> elit, 
                                    sed diam <span>nonummy</span> nibh euismod <span>tincidunt</span> ut laoreet dolore magna aliquam erat volutpat. Ut wisi <span>enim</span> ad minim veniam, quis <span>nostrud</span> exerci tation.
                                </div>
                                <div className='icons-container flex'>
                                    <div className='flex'>
                                        <img className='icons' src={euro} alt='euro' />
                                        <h4>Estimation</h4>
                                    </div>
                                    <div className='flex'>
                                        <img className='icons' src={loupe} alt='loupe' />
                                        <h4>Recherche</h4>
                                    </div>
                                    <div className='flex'>
                                        <img className='icons' src={pieces} alt='pieces' />
                                        <h4>Investissement</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='recherche'>
                        <h3>Recherche d'une résidence</h3>
                        <div className='flex'>
                            <div className='text'>
                                Lorem ipsum dolor sit <span>amet</span>, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore <span>magna</span> aliquam erat volutpat. Ut wisi enim ad minim veniam, quis <span>nostrud</span> exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. 
                                Duis autem vel eum iriure dolor in <span>hendrerit</span> in vulputate velit esse molestie consequat, vel illum <span>dolore</span> eu feugiat nulla facilisis at vero eros et accumsan et iusto odio <span>dignissim</span> qui blandit praesent luptatum zzril delenit augue duis <span>dolore</span> te feugait nulla facilisi.
                            </div>
                            <img className='house' src={house} alt='house-svg' />
                        </div>
                    </div>
                </div>
                <div className='right'>
                    <div className='patrimoine'>
                        <h3>Patrimoine</h3>
                        <div className='text'>
                            Lorem ipsum dolor sit amet, <span>consectetuer</span> adipiscing elit, sed diam nonummy nibh <span>euismod</span> tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in <span>hendrerit</span> in vulputate velit esse molestie consequat, vel illum dolore
                        </div>
                        <div className='de-container flex'>
                            <img className='de' src={de1} alt='house-de1' />
                            <img className='de' src={de2} alt='house-de2' />
                        </div>
                        <div className='text'>
                            Ut wisi enim ad minim <span>veniam</span>, quis nostrud exerci tation ullamcorper suscipit <span>lobortis</span> nisl ut aliquip ex ea commodo consequat. Duis <span>autem</span> vel eum iriure dolor in hendrerit in vulputate <span>velit</span> esse molestie consequat
                        </div>
                    </div>
                    <div className='financement'>
                        <h3>Financement</h3>
                        <div className='text'>
                            Dolor sit amet, consectetuer <span>adipiscing</span> elit, sed diam nonummy nibh euismod tincidunt ut laoreet <span>dolore</span> magna aliquam erat volutpat. in <span>hendrerit</span> in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla <span>facilisis</span> at vero eros et accumsan et iusto odio dignissim qui blandit praesent <span>luptatum</span> zzril delenit augue duis dolore te feugait nulla facilisi.
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
};

export default Services;