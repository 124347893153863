import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { motion, useScroll, useTransform} from "framer-motion";

import Nav from '../components/Nav';
import PageHeader from '../components/PageHeader';
import BesoinLouer from '../components/BesoinLouer';
import ScrollingPartenaire from '../components/ScrollingPartenaire';
import Footer from '../components/Footer';
import DossierLouer from '../components/DossierLouer';
import Phone from '../components/Phone';
import Application from '../components/Application';

const Louer = () => {
    const phone = useRef();
    const application = useRef();

    const { scrollYProgress } = useScroll();

    const [phoneState, setPhoneState] = useState(false);

    const [middlePercentage, setMiddlePercentage] = useState(null);
    const [scrollPercentageStart, setScrollPercentageStart] = useState(null);
    const [scrollPercentageEnd, setScrollPercentageEnd] = useState(null);

    const toScrollY = useTransform(scrollYProgress, [scrollPercentageStart, scrollPercentageEnd], [0, 750]);
    const middleTrigger = useTransform(scrollYProgress, [scrollPercentageStart, middlePercentage], [0, 1]);

    useLayoutEffect(() => {
        const phoneRect = phone.current.getBoundingClientRect();
        const applicationRect = phone.current.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        
        const offsetStart = (phoneRect.top + scrollTop)+350;
        const offsetEnd = ((applicationRect.top + scrollTop) + applicationRect.height)+600;
        
        const elementScrollStart = offsetStart/ document.body.clientHeight;
        const elementScrollEnd = offsetEnd / document.body.clientHeight;
         
        setScrollPercentageStart(elementScrollStart);
        setScrollPercentageEnd(elementScrollEnd);

        setMiddlePercentage((offsetStart + ((offsetEnd-offsetStart)/2))/ document.body.clientHeight)
    },[]);

    useEffect( () => {
        middleTrigger.on("change", ()=>{
            if(middleTrigger.get() === 1){
                setPhoneState(true);
            }else{
                setPhoneState(false);
            }
        })
    },[middleTrigger]);
    
    return (
        <>
            <Nav />
            <PageHeader page='louer' />
            <BesoinLouer />
            <div className='wrapper' style={{paddingTop: '40px',position: 'relative'}}>
                <div style={{paddingLeft: '100px',paddingTop: '90px'}}>
                    <DossierLouer />
                </div>
                <div ref={phone} className='absolute-phone' >
                    <motion.div style={{y:toScrollY}}>
                        <Phone state={phoneState} />
                    </motion.div>
                </div>
            </div>
            <div ref={application}>
                <Application />
            </div>
            <ScrollingPartenaire />
            <Footer />
        </>
    );
};

export default Louer;