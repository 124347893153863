import React from 'react';

const ScrollingText = ({lines}) => {
    return (
        <div className='scrolling-text'>
            { lines.map((line, index) => {
                if(index/2 !== 0){
                    return (
                        <div key={index} className='text-wrapper'>
                            <p className='text toleft'>{line}&nbsp;</p>
                            <p className='text toleft'>{line}&nbsp;</p>
                        </div>
                    )
                }else{
                    return (
                        <div key={index} className='text-wrapper'>
                            <p className='text toright'>{line}&nbsp;</p>
                            <p className='text toright'>{line}&nbsp;</p>
                        </div>
                    )
                }
            })}
        </div>
    );
};

export default ScrollingText;