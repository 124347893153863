import React from 'react';
import Nav from '../components/Nav';
import PageHeader from '../components/PageHeader';
import Key from '../components/Key';
import House from '../components/House';
import Footer from '../components/Footer';
import Connect from '../components/Connect';
import PourquoiAcheter from '../components/PourquoiAcheter';


const Acheter = () => {
    return (
        <>
            <Nav />
            <PageHeader page='acheter' />
            <Key />
            <PourquoiAcheter />
            <Connect />
            <House />
            <Footer />
        </>
    );
};

export default Acheter;