import React from 'react';
import { NavLink } from "react-router-dom";

import background from './assets/home-bg.png';

const Presentation = () => {
    return (
        <div className='presentation'>
            <img className="home-bg" src={background} alt="home-bg" />
            <div className='presentation-header'>
                <img className="logo" src="/logo.png" alt="logo" />
                <div className='description'>
                    <h1>LD IMMOBILIER</h1>
                    <h2>L'agent immobilier <span>connecté</span> pour vos biens d'exceptions<br/>Flexible et disponible, prise de devis en ligne, rendez-vous <span>physique</span> ou <span>distanciel</span><br/>Fort de <span>15 ans</span> d'expérience dans le domaine du <span>luxe</span>, nos agents trouvez votre bien rapidement </h2>
                </div>
            </div>
            <div className='presentation-footer'>
                    <NavLink className='presentation-button' to ='/vendre'>Vendre</NavLink>
                    <NavLink className='presentation-button' to ='/louer'>Louer</NavLink>
                    <NavLink className='presentation-button' to ='/acheter'>Acheter</NavLink>
            </div>
            <div className='footer-contact'>
                <NavLink className='presentation-button' to ='/contact'>Contact</NavLink>
            </div>
        </div>
    );
};

export default Presentation;