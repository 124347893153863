import React from 'react';
import CommentCard from '../CommentCard';

//images
import stats from './assets/stats.png';
import avatar1 from './assets/avatar1.png';
import avatar2 from './assets/avatar2.png';
import avatar3 from './assets/avatar3.png';

const Resultats = () => {
    let comments = [
        {
            img : avatar1,
            name : "Henry Dupont",
            rate : 5,
            desc : "Lorem ipsum dolor sit amet, dolor sit amet",
            text : "« Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure. » ",
            date: "24-11-22"
        },
        {
            img : avatar2,
            name : "John Doe",
            rate : 4,
            desc : "Lorem ipsum dolor sit amet, dolor sit amet",
            text : "« Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure. » ",
            date: "12-02-22"
        },
        {
            img : avatar3,
            name : "Marry Laporte",
            rate : 5,
            desc : "Lorem ipsum dolor sit amet, dolor sit amet",
            text : "« Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure. » ",
            date: "05-01-23"
        }
    ]

    return (
        <div className='resultats wrapper'>
            <h3>Des résultats très positifs</h3>
            <div className='stats-container'>
                <img className='stats' src={stats} alt='stats' />
                <div className='text'>Lorem ipsum dolor sit amet, <span>consectetuer</span> adipiscing elit, sed diam nonummy nibh euismod <span>tincidunt</span> ut laoreet dolore magna aliquam erat volutpat. Ut wisi <span>enim</span> ad minim veniam, quis nostrud <span>exerci</span> tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis <span>autem</span> vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat,</div>
            </div>
            <div className='comments-container'>
                { comments.map((comment) => {
                    return <CommentCard comment={comment} key={comment.name}/>
                })}
            </div>
        </div>
    );
};

export default Resultats;