import React, {useState} from 'react';
import { Arrow, PhoneSVG, Video, Menu } from '../../styles/assets/SvgLibrary';
import topicons from './assets/top-icons.png';
import profil from './assets/profil.jpg';

const Phone = ({state}) => {
    const [rangeValue, setRangeValue] = useState(200000);

    const handleRange = (e) => {
        setRangeValue(e.target.value);
    }

    return (
        <div className='phone' style={state ? {transform:'rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateX(-300px)'} : {}}>
            <div className='phone-topcontainer'>
                <div className='phone-topbar'>
                    <p>14:23</p>
                    <img src={topicons} alt='top-icons' />
                </div>

                <div className='phone-welcome'>
                    <div className='phone-welcometxt' style={state ? {transform:'translateY(-150px)',opacity:0} : {}}>
                        <p className='grey'>Bonjour, Marc !</p>
                        <p>Bienvenue sur LD Immo</p>
                    </div>
                    <img className='phone-welcomeicon' style={state ? {transform:'translateY(-150px)',opacity:0} : {}} src="/logo.png" alt='phone-icon' height='27px' />
                    <div className='phone-msgtop' style={state ? {opacity:1} : {opacity:0}}>
                        <div className='msg-left'>
                            <Arrow width='25px' color='black' rotate='180deg' />
                            <img className='user-msg' src={profil} alt='user-msg'/>
                            <span>Martin</span>
                        </div>
                        <div className='msg-right'>
                            <PhoneSVG width='21px' color='black' />
                            <Video width='25px' color='black' />
                            <Menu width='25px' color='black' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='phone-body'>
                <div className='phone-card orange' style={state ? {transform:'translateX(150px)',opacity:0} : {}}>
                    <div className='card-body'>
                        <p className='card-title'>Orci varius natoque</p>
                        <p className='card-desc'>Fusce tempus a arcu nec porttitor mollis</p>
                        <div className='card-tag'>Economisez 20%</div>
                    </div>
                    <div className='card-footer'>
                        <p>En savoir plus</p>
                        <Arrow width='25px' color='white' />
                    </div>
                </div>
                <div className='phone-card blue' style={state ? {transform:'translateX(-150px)',opacity:0} : {}}>
                    <div className='card-body'>
                        <p className='card-title'>Orci varius natoque</p>
                        <p className='card-desc'>Fusce tempus a arcu nec porttitor mollis</p>
                        <div className='card-tag'>Economisez 20%</div>
                    </div>
                    <div className='card-footer'>
                        <p>En savoir plus</p>
                        <Arrow width='25px' color='white' />
                    </div>
                </div>
                <div className='phone-bodymsg'>
                    <div className='phone-bodybubble bubble-right' style={state ? {transform:'translateX(0px)',opacity:1} : {transform:'translateX(150px)',opacity:0}}>
                        <div className='card-title'>Martin</div>
                        <div className='phone-bodybubble-container'>
                            <span>Hello Marc, en quoi puis-je vous aider ?</span>
                        </div>
                    </div>
                    <div className='phone-bodybubble bubble-left' style={state ? {transform:'translateX(0px)',opacity:1} : {transform:'translateX(-150px)',opacity:0}}>
                        <div className='card-title'>Marc</div>
                        <div className='phone-bodybubble-container'>
                            <span>Bonjour Martin, pouvez vous définir mon budget ?</span>
                        </div>
                    </div>
                    <div className='phone-bodybubble bubble-right' style={state ? {transform:'translateX(0px)',opacity:1} : {transform:'translateX(150px)',opacity:0}}>
                        <div className='card-title'>Martin</div>
                        <div className='phone-bodybubble-container'>
                            <span>Choissez-le a l'aide du curseur ci-desous !</span>
                        </div>
                        <div className='msg-slider'>
                            <input className='slider slider-progress' type="range" min="100000" max="800000" step="100000" value={rangeValue} onChange={handleRange} />
                            <span className='slider-value'>{rangeValue}€</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='phone-bottomcontainer' style={state ? {transform:'translateY(100px)',opacity:0} : {}}>
                <p className='grey'>Bottom title</p>
                <div className='phone-bottom'>
                    <p className='phone-bottomtxt'>Proin a tincidunt magna. Nam malesuada nisl justo</p>
                    <div className='phone-bottombutton'><Arrow width='35px' color='white' /></div>
                </div>
            </div>
            <div className='phone-bottombar' style={state ? {backgroundColor:'#333333'} : {}}></div>
        </div>
    );
};

export default Phone;  