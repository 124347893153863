import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

import CardsContent from './assets/CardsContent';

const Slider = () => {
    const [maxDrag, setMaxDrag] = useState(0);
    const carousel = useRef();
    useEffect(() => {
        setMaxDrag(carousel.current.scrollWidth - carousel.current.offsetWidth);
     }, []);

    return (
            <motion.div ref={carousel} className='carousel' whileTap={{ cursor: "grabbing"}}>
                <motion.div drag="x" dragConstraints={{ right: 0, left : -maxDrag}} className='inner-carousel'>
                    {CardsContent.map((card, index) => {
                        return(
                            <div className='slider-item' style={{flex: '1 0 25%'}} key={index}>
                                <div className='item'>
                                    <img src={card.img} alt={'house'+index} />
                                    <div className='bandeau'>
                                        {card.title}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </motion.div> 
            </motion.div> 
    );
};

export default Slider;