import house1 from './sliderhouse1.jpg';
import house2 from './sliderhouse2.jpg';
import house3 from './sliderhouse3.jpg';
import house4 from './sliderhouse4.jpg';
import house5 from './sliderhouse5.jpg';
import house6 from './sliderhouse6.jpg';
import house7 from './sliderhouse7.jpg';
import house8 from './sliderhouse8.jpg';

const CardsContent = [{
    img : house1,
    title : "Lorem ipsum dolor sit amet, consectetuer"
}, {
    img : house2,
    title : "Lorem ipsum dolor sit amet, consectetuer"
},{
    img : house3,
    title : "Lorem ipsum dolor sit amet, consectetuer"
},{
    img : house4,
    title : "Lorem ipsum dolor sit amet, consectetuer"
},{
    img : house5,
    title : "Lorem ipsum dolor sit amet, consectetuer"
},{
    img : house6,
    title : "Lorem ipsum dolor sit amet, consectetuer"
},{
    img : house7,
    title : "Lorem ipsum dolor sit amet, consectetuer"
},{
    img : house8,
    title : "Lorem ipsum dolor sit amet, consectetuer"
}];

export default CardsContent;