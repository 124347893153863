import React from 'react';
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <div className='footer'>
            <div className='flex'>
                <div className='footer-col'>
                    <span>Contactez-nous</span>
                    <span>Nos agences</span>
                    <span>Nous rejoindre</span>
                    <span>Espace presse</span>
                </div>
                <div className='footer-col'>
                    <NavLink to ='/vendre'><span>Vendre</span></NavLink>
                    <NavLink to ='/louer'><span>Louer</span></NavLink>
                    <NavLink to ='/acheter'><span>Acheter</span></NavLink>
                </div>
                <div className='footer-col'>
                    <NavLink to ='/investir'><span>Investir</span></NavLink>
                    <NavLink to ='/estimer'><span>Estimer</span></NavLink>
                </div>
                <div className='footer-col'>
                    <span>Mentions légales</span>
                    <span>Conditions générales d'utilisation</span>
                    <span>Politique de confidentialité</span>
                    <span>Préférence cookie</span>
                </div>
            </div>
            <div className='copyright'>Copyright © AC Info</div>
        </div>
    );
};

export default Footer;