import React, {useState} from 'react';

//images
import billets from './assets/billets.png';
import pieces from './assets/pieces.png';
import assurance from './assets/assurance.png';
import bouclier from './assets/bouclier.png';
import horloge from './assets/horloge.png';
import pouce from './assets/pouce.png';
import Citation from '../Citation';
import Slider from '../Slider';

const ServicesVente = () => {
    const [billetWiggling, setBilletWiggling] = useState(false);
    const [piecesWiggling, setPiecesWiggling] = useState(false);
    return (
        <div className='services-vente'>
            <div className='top-container'>
                <div className='wrapper'>
                    <div className='flex-line'>
                        <div className='left-container'>
                            <div className='text-container'>
                                <h3>Estimer votre bien</h3>
                                <span>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat : Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et </span>
                            </div>
                            <img className={'billets ' + (billetWiggling ? 'wiggle' : '')} onMouseEnter={() => setBilletWiggling(true)} onAnimationEnd={() => setBilletWiggling(false)} src={billets} alt='billets' />
                        </div>
                        <div className='cube delais'>
                            <div className='picto'>
                                <img src={horloge} alt='horloge' />
                                <h4>Délais de vente garantie</h4>
                            </div>
                        </div>
                        <div className='cube protection'>
                            <div className='picto'>
                                <img src={bouclier} alt='bouclier' />
                                <h4>Protection juridique</h4>
                            </div>
                        </div>
                    </div>
                    <div className='flex-line'>
                        <div className='left-container'>
                            <div className='text-container'>
                                <h3>Estimer votre bien</h3>
                                <span>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat : Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et </span>
                            </div>
                            <img className={'pieces ' + (piecesWiggling ? 'wiggle' : '')} onMouseEnter={() => setPiecesWiggling(true)} onAnimationEnd={() => setPiecesWiggling(false)} src={pieces} alt='pieces' />
                        </div>
                        <div className='cube satisfaction'>
                            <div className='picto'>
                                <img src={pouce} alt='pouce' />
                                <h4>Garantie satisfaction</h4>
                            </div>
                        </div>
                        <div className='cube assurance'>
                            <div className='picto'>
                                <img src={assurance} alt='assurance' />
                                <h4>Assurance sinistre</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <Citation text='"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt"' author='Lorem ipsum , Lorem ipsum dolor , 2022'/>
            </div>
            <div className='immovable-type'>
                <div className='blue-square'>
                    <h3>Lorem ipsum</h3>
                    <span>Urban ou Rurale<br/>Ancien ou Moderne</span>
                </div>
                <div className='slider-frame'>
                    <Slider />
                </div>
            </div>
        </div>

    );
};

export default ServicesVente;