import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const Citation = ({text, author}) => {

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const characterAnimation = {
        hidden: {
          opacity: 0,
          y: `0.25em`,
        },
        visible: {
          opacity: 1,
          y: `0em`,
          transition: {
            duration: 0.3,
            ease: [0.2, 0.65, 0.3, 0.9],
          },
        },
      };

    return (
    <div ref={ref} className='citation'>
        <div className='text'>
            {text.split(" ").map((word, index) => {
                return (
                <motion.span className='word'
                    key={index}
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                    transition={{
                        delayChildren: index * 0.25,
                        staggerChildren: 0.04,
                }}>
                    {word.split("").map((character, index) => {
                        return (
                            <motion.span className='character'
                            key={index}
                            variants={characterAnimation}
                            >
                            {character}
                            </motion.span>
                        );
                    })}
                </motion.span>
                );
            })}
        </div>
        <span className='author'>{author}</span>
    </div>
    );
};

export default Citation;