import React from "react";

const Immovable = ({ width, color }) => (
  <svg
    fill={color}
    height={width}
    width={width}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M507.108,255.327l-50.76-50.761V94.609c0-9.217-7.479-16.696-16.696-16.696H372.87c-9.217,0-16.696,7.479-16.696,16.696
			v9.783l-88.37-88.37c-6.521-6.521-17.087-6.521-23.609,0L4.891,255.326c-6.521,6.521-6.521,17.087,0,23.609s17.087,6.521,23.609,0
			l27.153-27.152v232.391c0,9.217,7.479,16.696,16.696,16.696h367.304c9.217,0,16.696-7.479,16.696-16.696V251.783l27.153,27.153
			c6.522,6.522,17.086,6.522,23.609,0C513.631,272.413,513.631,261.848,507.108,255.327z M378.435,417.392
			c0,9.217-7.479,16.696-16.696,16.696h-50.087c-4.424,0-8.674-1.761-11.804-4.892l-84.717-84.717
			c-3.131,0.381-6.185,0.565-9.218,0.565c-46.032,0-83.478-37.446-83.478-83.478c0-46.032,37.446-83.478,83.478-83.478
			c46.032,0,83.478,37.446,83.478,83.478c0,3.033-0.185,6.087-0.565,9.217l29.065,29.065c3.131,3.131,4.892,7.381,4.892,11.805
			v22.261h22.261c4.424,0,8.674,1.761,11.804,4.892l16.696,16.696c3.131,3.13,4.892,7.38,4.892,11.804V417.392z M422.957,171.174
			l-33.391-33.391v-26.478h33.391V171.174z"
        />
      </g>
    </g>
    <g>
      <g>
        <circle cx="205.913" cy="261.566" r="16.696" />
      </g>
    </g>
  </svg>
);

const Notary = ({ width, color }) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 192 192"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <mask id="a" fill="#ffffff">
      <rect width="144" height="144" x="24" y="24" rx="7" />
    </mask>
    <rect
      width="144"
      height="144"
      x="24"
      y="24"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth="24"
      mask="url(#a)"
      rx="7"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
      d="M47 52h98M47 76h98m-98 24h39"
    />
  </svg>
);

const Broker = ({ width, color }) => (
  <svg
    fill={color}
    width={width}
    height={width}
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M25 3C24.8405 3 24.680156 3.0372812 24.535156 3.1132812L3.5351562 14.113281C3.2061563 14.286281 3 14.628 3 15L3 17C3 17.552 3.448 18 4 18L46 18C46.553 18 47 17.552 47 17L47 15C47 14.628 46.794844 14.286281 46.464844 14.113281L25.464844 3.1132812C25.319844 3.0372812 25.1595 3 25 3 z M 25 9C26.656 9 28 10.343 28 12C28 13.657 26.656 15 25 15C23.343 15 22 13.657 22 12C22 10.343 23.343 9 25 9 z M 8 20C7.448 20 7 20.448 7 21L7 35L13 35L13 21C13 20.448 12.552 20 12 20L8 20 z M 18 20C17.448 20 17 20.448 17 21L17 35L23 35L23 21C23 20.448 22.552 20 22 20L18 20 z M 28 20C27.448 20 27 20.448 27 21L27 35L32.853516 35C31.417516 33.807 30.5 32.009 30.5 30C30.5 27.925 31.481 26.077719 33 24.886719L33 21C33 20.448 32.552 20 32 20L28 20 z M 38 20C37.448 20 37 20.448 37 21L37 23.5C39.7 23.5 42.019 25.154906 43 27.503906L43 21C43 20.448 42.552 20 42 20L38 20 z M 37 25.5C34.519 25.5 32.5 27.519 32.5 30C32.5 32.481 34.519 34.5 37 34.5C39.481 34.5 41.5 32.481 41.5 30C41.5 27.519 39.481 25.5 37 25.5 z M 5 37C4.448 37 4 37.447 4 38L4 40L3 40C2.448 40 2 40.447 2 41L2 44C2 44.553 2.448 45 3 45L26 45L26 42.558594C26 40.351594 26.967422 38.381 28.482422 37L5 37 z M 33.558594 37C30.492594 37 28 39.492594 28 42.558594L28 47C28 47.553 28.447 48 29 48L45 48C45.553 48 46 47.553 46 47L46 42.558594C46 39.492594 43.506406 37 40.441406 37L33.558594 37 z" />
  </svg>
);

export { Immovable, Notary, Broker };

export default Immovable;
