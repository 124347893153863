import React, { useRef, useState } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer"
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [formMessage, setFormMessage] = useState("");
  const form = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    emailjs
      .sendForm(
        "service_7zk0pti",
        "template_gtvoqek",
        form.current,
        "_KZ3Or5XJMg758hxf"
      )
      .then(
        (result) => {
            setFormMessage("Message envoyé avec succès");
        },
        (error) => {
            setFormMessage("Echec de l'envois du message :" + error.text);
        }
      );
  }

  return (
    <>
      <Nav />
      <div className="contact wrapper">
        <div className="left">
          <img className="left-logo" src="/logo.png" alt="logo" />
        </div>
        <form className="form" ref={form} onSubmit={handleSubmit(onSubmit)}>
          <h2>Contacter nous via le formulaire :</h2>
          <label htmlFor="user_name">Nom :</label>
          <input
            id = "user_name"
            type="text"
            name="user_name"
            {...register("user_name", { required: "Nom requis", maxLength:{value: 25, message: "Le nom ne peux pas faire plus de 25 caractères"} })}
          />
          {errors.user_name && <span className="err">{errors.user_name?.message}</span>}
          <label htmlFor="user_email">Email : </label>
          <input
            id ="user_email"
            type="email"
            name="user_email"
            {...register("user_email", {
              required: "Adresse email requise",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Format d'email invalide",
              },
            })}
          />
          {errors.user_email && <span className="err">{errors.user_email?.message}</span>}
          <label htmlFor="message">Message :</label>
          <textarea
            id = "message"
            name="message"
            {...register("message", { required: "Message requis", maxLength:{value: 400, message: "Le message ne peux pas faire plus de 400 caractères"}})}
          />
          {errors.message && <span className="err">{errors.message?.message}</span>}
          <input type="submit" value="Envoyer" />
          {formMessage === "Message envoyé avec succès" ? <span className="success">{formMessage}</span> : <span className="err">{formMessage}</span>}
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
