import React, {useState} from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import variables from '../styles/_variables.scss';

const Application = () => {
    const [activeTitle, setActiveTitle] = useState(0);
    const advantages = [
        {
            title : 'Ergonomie',
            color: '#FFFFFF',
            lines : ["Curabitur et magna sed",
            "Nunc dolor dolor, volutpat a facilisis",
            "Quisque purus quam, malesuada nec",
            "Maecenas pretium quis risus vitae feugiat"]
        },
        {
            title : 'Disponibilité',
            color: variables.color4,
            lines : ["Nunc dolor dolor, volutpat a facilisis",
            "Quisque purus quam, malesuada nec",
            "Curabitur et magna sed",
            "Maecenas pretium quis risus vitae feugiat"]
        },
        {
            title : 'Portabilité',
            color: '#000000',
            lines : ["Maecenas pretium quis risus vitae feugiat",
            "Curabitur et magna sed",
            "Quisque purus quam, malesuada nec",
            "Nunc dolor dolor, volutpat a facilisis"]
        }
    ];
    
    return (
        <div className='application'>
            <div className='application-content'>
                <div className='col titles'>
                    <p>Avantages</p>
                    {advantages.map((object, index) => {
                        return (<motion.h3 key={index} animate={activeTitle === index ? {color: object.color} : {} } whileHover={{color: object.color}} className={'title'} onClick={() => setActiveTitle(index)}>{object.title}</motion.h3>);                   
                    })}
                </div>
                <div className='col'></div>
                <div className='col advantages'>
                    <p>Exemples</p>
                        {advantages[activeTitle].lines.map((line, index) => {
                            return (<div key={activeTitle+"-"+index}  className='line'>
                                {(index+1) +". "} 
                            <AnimatePresence><motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}  transition={{ duration: 1 }}>
                                {line}
                            </motion.span></AnimatePresence>
                            </div>);                   
                        })}
                </div>
            </div>
        </div>
    );
};

export default Application;