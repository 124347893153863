import React from 'react';

import { Check } from '../styles/assets/SvgLibrary';

const DossierLouer = () => {
    return (
        <div className='dossierlouer'>
            <div className='dossier-tips'>
                <div className='tag'>Dossier</div>
                <div className='dossier-steps'>
                    <h3>Optimisez votre recherche</h3>
                    <div className='dossier-desc'>Suspendisse quis tortor ut sapien faucibus dictum iaculis in arcu. Aliquam vitae egestas nisi. Nulla sodales viverra pulvinar. Aenean et lacus ut eros molestie vestibulum pharetra tempor eros. Aenean accumsan eget arcu vitae auctor.</div>
                    <div className='dossier-list'>
                        <div className='dossier-listcontainer'>
                            <Check width='20px' colorBack='#2b8bbb' colorInner='white' />
                            <p>Etiam sollicitudin tincidunt dolor, vitae hendrerit lacus</p>
                        </div>
                        <div className='dossier-listcontainer'>
                            <Check width='20px' colorBack='#2b8bbb' colorInner='white' />
                            <p>Curabitur vel cursus risus. Integer in augue lorem</p>
                        </div>
                        <div className='dossier-listcontainer'>
                            <Check width='20px' colorBack='#2b8bbb' colorInner='white' />
                            <p>Nullam semper scelerisque elit vel pellentesque</p>
                        </div>
                    </div>
                </div>
                <div className='dossier-steps'>
                    <h3>Démarquez-vous</h3>
                    <div className='dossier-desc'>Duis molestie lorem ac mauris sagittis hendrerit. Donec interdum nibh sed venenatis convallis. Fusce sit amet luctus nisl, quis luctus nisl. Nulla in elit sit amet est viverra iaculis in id ante. Donec et nisl mi.</div>
                    <div className='dossier-list'>
                        <div className='dossier-listcontainer'>
                            <Check width='20px' colorBack='#2b8bbb' colorInner='white' />
                            <p>Sed pulvinar fermentum turpis</p>
                        </div>
                        <div className='dossier-listcontainer'>
                            <Check width='20px' colorBack='#2b8bbb' colorInner='white' />
                            <p>Pellentesque habitant morbi tristique senectus</p>
                        </div>
                        <div className='dossier-listcontainer'>
                            <Check width='20px' colorBack='#2b8bbb' colorInner='white' />
                            <p>Etiam id tincidunt mauris. Nulla mattis quam nec arcu</p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default DossierLouer;