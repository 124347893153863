import React from 'react';

//images
import manoir from './assets/manoir.png';
import aide from './assets/accompagnement.png';

const Avantages = () => {
    return (
        <div className='avantages'>
            <div className='exception flex'>
                <div className='exception-text'>
                    <h3>Biens d'exception</h3>
                    <div className='text'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero </div>
                </div>
                <img className='manoir' src={manoir} alt='manoir' />
            </div>
            <div className='accompagnement flex'>
                <img className='aide' src={aide} alt='accompagnement' />
                <div className='accompagnement-text'>
                    <h3>Accompagnement</h3>
                    <div className='text'>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</div>
                </div>
            </div>
            
        </div>
    );
};

export default Avantages;