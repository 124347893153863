import React, { useRef, useState } from 'react';
import arrow from './assets/arrow.png';

const Menu = ({ menu }) => {
    const textRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const openMenu = () => {
        setIsOpen(!isOpen);
        textRef.current.scrollIntoView();
    }
    return (
        <div className='menu-container'>
            <div className='title-row' onClick={openMenu}>
                <h4 className='title' >{menu.title}</h4>
                <img className={'arrow ' +(isOpen ? 'rotate' : '')} src={arrow} alt='arrow' />
            </div>
            <div ref={textRef} style={isOpen ? { height: textRef.current.scrollHeight +"px" } : { height: "0px" }} className='text-container'>
                <div>{menu.text}</div>
            </div>
        </div>
    );
}

const MenuWrapper = ({menuObject}) => {

    return (
        <div className='menu-wrapper'>
            {menuObject.map((menu, index) => {
                 return (
                    <Menu key={index} menu={menu} />
                 );
            })}
        </div>
    );
};

export default MenuWrapper;